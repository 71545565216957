1<template>
  <div>
    <div class="title">
      {{ formObj.name }}
    </div>
    <table class="mytb draft_table">
      <tr class="thtt">
        <th>发文字号</th>
        <td colspan="3" class="textLeft">
          {{ formObj.documentNumber }}
        </td>
      </tr>
      <tr class="thtt">
        <th>紧急程度</th>
        <td class="textLeft">
          {{ formatSelect('flow_urgency_degree', formObj.jjcd) }}
        </td>
        <th>密级程度</th>
        <td class="textLeft">
          {{ formatSelect('flow_secrecy_degree', formObj.miji) }}
        </td>
      </tr>
      <tr class="thtt">
        <th colspan="1">拟稿单位</th>
        <td colspan="3" class="textLeft">
          <template v-for="(o, i) in getDraftCompany(formObj.draftCompany)">
            <a-tag class="tags flex0" color="blue" v-if="o.corpName" :key="'ngdw_list_span' + i">{{
              o.corpName
            }}</a-tag>
            <a-tag class="tags flex0" :key="'ngdw_list_tag' + i" color="blue" v-else>
              <OpenData :type="o.type" :openid="o.departmentId" :corpid="o.corpId"></OpenData>
            </a-tag>
          </template>
        </td>
      </tr>
      <tr class="thtt">
        <th>拟稿人</th>
        <td class="textLeft">
          <a-tag
            class="tags flex0"
            color="blue"
            v-for="(o, i) in getDraftCompany(formObj.userId)"
            :key="'ngry_list' + i"
          >
            <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
          </a-tag>
        </td>
        <th>联系电话</th>
        <td class="textLeft">
          {{ formObj.tellPhone }}
        </td>
      </tr>
      <tr class="thtt">
        <th>文稿标题</th>
        <td colspan="3" class="textLeft">
          {{ formObj.title }}
        </td>
      </tr>

      <tr class="thtt">
        <th>正文</th>
        <td colspan="3" class="textLeft" :style="{ paddingLeft: formObj.mainFile ? '' : '0' }">
          <div v-if="formObj.mainFile" class="contentFileContainer">
            <div class="fileName">{{ formObj.mainFile.fileName }}</div>
            <fileDownAndPreView :file="formObj.mainFile" />
          </div>
          <div v-else class="upload_text htmlContainer">
            <showEditor :content="formObj.content"></showEditor>
          </div>
        </td>
      </tr>
      <tr class="">
        <th>附件</th>
        <td colspan="3" class="textLeft">
          <div class="filsContainer">
            <div class="fileList" v-for="(o, i) in formObj.fileIds" :key="i">
              <div class="fileName">{{ o.fileName }}</div>
              <fileDownAndPreView :file="o" />
            </div>
          </div>
        </td>
      </tr>

      <tr class="bigtt">
        <th>拟办意见</th>
        <td colspan="3" style="padding: 0">
          <a-textarea readOnly v-model="formObj.deviseOpinion" :auto-size="{ minRows: 5 }" />
        </td>
      </tr>

      <template>
        <nodes :list="formatList(list)" />
      </template>
    </table>
  </div>
</template>

<script>
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import nodes from '@/views/fileStream/documentApproval/documentManage/nodes.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import fileDownAndPreView from './fileDownAndPreView.vue'
import showEditor from './showEditor.vue'
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
    formObj: {
      type: Object,
      default() {
        return { name: '发文稿纸' }
      },
    },
  },
  computed: {
    list() {
      if (this.formObj.documentNodeList && this.formObj.documentNodeList.length) {
        const nodes = this.formObj.documentNodeList.slice()
        const targetArray = nodes.filter((j) => j.sort !== -1 && ![99, 100].includes(j.sort))
        return targetArray.map(({ name, nodes }) => ({ name, nodes }))
      }
      return []
    },
  },
  components: {
    OpenData,
    fileDownAndPreView,
    nodes,
    showEditor,
  },
  data() {
    return {
      dom: [],
      flow_secrecy_degree: [],
      flow_urgency_degree: [],
      ngdw_list: [],
      ngry_list: [],
      type: '',
      fileFileIdss: [],
    }
  },
  created() {
    this.getDict()
  },
  methods: {
    formatListMess(node) {
      const arr = []
      if (node.deptInfos && node.deptInfos.length > 0) {
        node.deptInfos.forEach((dep) => {
          dep.deptUsers.forEach((k) => {
            arr.push(k)
          })
        })
      }
      node.nodes = [...node.nodes, ...arr].filter((j) => j.userId && j.approvalFlag !== 2)
    },
    formatList(list) {
      const listCopy = JSON.parse(JSON.stringify(list))
      listCopy.forEach((j) => {
        this.formatListMess(j)
      })
      return listCopy.filter((j) => j.sort != -1 && ![99, 100].includes(j.sort))
    },
    // 获取字典
    getDict() {
      sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
        this.flow_secrecy_degree = res.data
      })
      sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
        this.flow_urgency_degree = res.data
      })
    },
    getDraftCompany(draftCompany) {
      if (!draftCompany) return []
      return JSON.parse(draftCompany)
    },
    // 情况表单
    clear_form() {
      this.form.resetFields()
      this.ngdw_list = []
    },
    Filter(s) {
      const values = this.flow_secrecy_degree.filter((item) => Number(item.code) === Number(s))
      if (values.length > 0) {
        return values[0].value
      }
    },
    formatSelect(key, jjcd) {
      return this[key].find((j) => Number(j.code) === Number(jjcd))?.value
    },
  },
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
::v-deep .ant-select-selection,
::v-deep .ant-input {
  border: none;
  box-shadow: none;
  &:focus {
    box-shadow: none;
  }
}

.contentFileContainer {
  display: flex;
  align-items: center;
}

.upload_file {
  display: flex;
  align-items: center;
  line-height: 0.7;
}
.font {
  font-size: 16px;
}

.filsContainer {
  min-height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  .fileList {
    display: flex;
    align-items: center;
  }
  .upload_file {
    margin-bottom: 8px;
  }
}

.tags {
  margin-bottom: 2px;
}
.upload_text {
  // cursor: pointer;
  &.htmlContainer {
    min-height: 200px;
    overflow: auto;
  }
}

.mytb {
  width: 100%;
  border: 1px solid black;
  font-size: 14px;
  table-layout: fixed;
  th,
  td {
    text-align: center;
    border: 1px solid black;
    padding-left: 11px;
    &.textLeft {
      text-align: left;
    }
  }
  .thtt {
    height: 50px;
  }
  .tdtt {
    height: 50px;
  }
  .bigtt {
    height: 150px;
  }
  .smalltt {
    height: 50px;
  }
  .align_l {
    td {
      text-align: left;
      padding: 15px;
      vertical-align: text-top;
      white-space: pre-line;
    }
  }
}
.draft_table {
  .list_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .my-item {
      width: 40%;
      margin: 0 5px;
    }
    .list_btn {
      width: 100%;
    }
  }
  /deep/ .ant-form-item {
    margin-bottom: 0;
  }
  ::v-deep .ant-select-selection,
  ::v-deep .ant-input {
    border: none;
    box-shadow: none;
    &:focus {
      box-shadow: none;
    }
  }
}
.tags {
  line-height: 32px;
}
.title {
  height: 60px;
  width: 50%;
  margin: 0 auto;
  color: red;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
}
</style>
