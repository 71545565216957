1<template>
    <div class="my-form margin-t-105">
        <a-form :form="form">
            <template>
                <div class="tip">
                    <div>注：</div>
                    <div>
                        <div>可快速发文，不用领导审批的发文</div>
                        <div>由拟稿人负责编辑好选择接收人后可直接发送</div>
                    </div>
                </div>
                <div class="line"></div>
                <template v-if="selectTemplateId">
                    <templateNodes :nodes="selectTemplate.nodeList"></templateNodes>
                </template>
                <div v-else class="font">
                    <div class="nodes">
                        <div class="line-item">
                            <span class="sp-icon"> <a-icon type="user-add" /></span>
                            接收人
                            <div class="left-line flex0">
                                <div class="tag-box flex-wrap">
                                    <div v-for="(o1, i1) in mainList" :key="i1" class="flex0">
                                        <a-tag color="blue" class="nodeTags flex0">
                                            <OpenData
                                                :type="o1.type"
                                                :openid="o1.userId || o1.id"
                                                :corpid="o1.corpId"
                                            ></OpenData>
                                            <a-button type="link" icon="close" @click="mainList.splice(i1, 1)" />
                                        </a-tag>
                                        <a-icon type="arrow-right" class="arrow-right" />
                                    </div>
                                    <a-button
                                        type="primary"
                                        icon="plus"
                                        ghost
                                        class="plus-bt font"
                                        @click="showSelctDialog('mainList', 2, null, allList)"
                                    >
                                    </a-button>
                                </div>
                            </div>
                        </div>
                        <div class="line-item">
                            <span class="sp-icon"> <a-icon type="user-add" /></span>
                            抄送人
                            <div class="left-line left-line-last flex0">
                                <div class="tag-box flex-wrap">
                                    <div v-for="(o1, i1) in csr_list" :key="i1" class="flex0">
                                        <a-tag color="blue" class="nodeTags flex0">
                                            <OpenData
                                                :type="o1.type"
                                                :openid="o1.userId || o1.id"
                                                :corpid="o1.corpId"
                                            ></OpenData>
                                            <a-button type="link" icon="close" @click="csr_list.splice(i1, 1)" />
                                        </a-tag>
                                        <a-icon type="arrow-right" class="arrow-right" />
                                    </div>
                                    <a-button
                                        type="primary"
                                        icon="plus"
                                        ghost
                                        class="plus-bt font"
                                        @click="showSelctDialog('csr_list', 2, null, allList)"
                                    >
                                    </a-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div class="submit-bt">
                <a-popconfirm
                    placement="topRight"
                    title="发布公文后公文不能修改，确定发送公文！"
                    @confirm="() => handleSubmit(null, 2)"
                >
                    <a-button type="primary"> 立即发布 </a-button>
                </a-popconfirm>
                <a-button type="primary" @click="handleSubmit($event, 1)" ghost> 存为草稿 </a-button>
            </div>
        </a-form>
        <selectPersonDialog ref="selectPersonDialog" @selectTodoMsg="selectTodoMsg" />
    </div>
</template>

<script>
import { documentBillAddDoc, documentBillDetail, documentBillEditDoc } from '@/api/modular/fileStream/documentManage'
import { selectPerson } from '@/mixins'
import { EventBus } from '@/views/fileStream/documentManage/bus.js'
import { customFromValidate } from '@/views/fileStream/documentManage/minxins.js'
import templateNodes from '@/views/fileStream/documentManage/templateNodes.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getCorpId,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
import nodes from '@/views/fileStream/documentSend/components/nodes.vue'
import moment from 'moment'

export default {
    components: {
        OpenData,
        templateNodes,
        selectPersonDialog,
        nodes,
    },
    mixins: [selectPerson, customFromValidate],
    computed: {
        allList() {
            return [...this.mainList, ...this.csr_list]
        },
    },
    data() {
        return {
            moment,
            subLoad: false,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            form: this.$form.createForm(this),
            selectTemplateId: '',
            selectTemplate: null,
            csr_list: [],
            mainList: [],
            form_id: null,
            form_state: {},
            // 表单对象
            tableForm: null,
        }
    },
    props: {
        content: {
            type: String,
            default: '',
        },
        fileIds: {
            type: Array,
            default() {
                return []
            },
        },
        todoType: {
            type: String,
            default: 'registration',
        },
        officialId: {
            type: String,
            default: '',
        },
        randomCode: 0,
    },
    watch: {
        randomCode: {
            immediate: true,
            handler() {
                this.get_info()
            },
        },
        selectTemplateId(val) {},
    },
    created() {
        this.getFormStateData()
    },
    destroyed() {
        EventBus.$off('form_state', {})
    },
    methods: {
        getFormStateData() {
            EventBus.$on('form_state', (obj, form) => {
                this.form_state = obj
                this.tableForm = form
            })
        },
        getDataType({ deptId }) {
            return deptId ? 'departmentName' : 'userName'
        },
        formatDraftId(draftId, key) {
            if (!draftId) return
            return JSON.parse(draftId)[0][key]
        },
        resetMess() {
            this.mainList = []
            this.csr_list = []
            this.form_state = {}
            this.form.resetFields()
            this.selectTemplateId = ''
        },
        resetList() {
            this.resetMess()
        },
        // 删除多余属性
        delEmptKey(obj) {
            const objCopy = { ...obj }
            const keys = Object.keys(objCopy)
            for (const index of keys) {
                if (!objCopy[index]) delete objCopy[index]
            }
            return objCopy
        },
        get_info() {
            this.form_id = this.officialId
            const id = this.form_id
            if (id) {
                documentBillDetail({ id }).then((res) => {
                    const {
                        name,
                        urgencyDegree,
                        secrecyDegree,
                        deviseOpinion,
                        draftId,
                        // approvalWay,
                        tellPhone,
                        summary,
                        title,
                        documentNodeList,
                        documentFileList, //附件
                        documentNumber,
                        mainFile, //正文
                        checkIds,
                        draftCompany,
                        mainIds,
                        sendIds,
                        outDraftCompany,
                        outDraftDesc,
                        meetCompany,
                        meetDesc,
                        templateId,
                    } = res.data
                    const form = {
                        name,
                        meetDesc,
                        meetCompany,
                        outDraftCompany,
                        outDraftDesc,
                        jjcd: urgencyDegree ? String(urgencyDegree) : '',
                        miji: secrecyDegree ? String(secrecyDegree) : '',
                        nbyj: deviseOpinion,
                        draftId: this.formatDraftId(draftId),
                        checkIds: this.formatDraftId(checkIds),
                        draftCompany: this.formatDraftId(draftCompany),
                        wjbt: title,
                        wgbt: title,
                        nodes: documentNodeList,
                        tellPhone,
                        attach: mainFile && [mainFile], //正文
                        fileIds: Array.isArray(documentFileList) ? documentFileList : [], //附件
                        summary,
                        documentNumber,
                        templateId,
                    }
                    this.csr_list = this.formatDraftId(sendIds) // 抄送人
                    this.mainList = this.formatDraftId(mainIds) // 主送人
                    this.tableForm.setFieldsValue(form)
                    //
                    this.$emit('setDetail', form)
                })
            } else {
                this.resetMess()
            }
        },
        async handleSubmit(e, status) {
            const { filter, csr_list, mainList } = this
            if (!mainList?.length) {
                this.$message.error('审批流程未选择，请选择审批人员')
                return
            }
            if (this.subLoad) return
            let arr = await this.handlerNodeList()
            // let arr = []
            let func = documentBillAddDoc
            const id = this.form_id
            if (id) {
                func = documentBillEditDoc
            }
            e && e.preventDefault()
            const getCorpName = (ngdwListInput) => {
                if (!ngdwListInput) return ''
                return JSON.stringify([
                    {
                        type: 'departmentName',
                        corpId: getCorpId(),
                        id: '',
                        corpName: ngdwListInput,
                    },
                ])
            }
            this.tableForm.validateFields((err, values) => {
                this.setFromValidateState({ fromValidateState: true })
                if (!err) {
                    let params = {
                        draftCompany: this.form_state.draftCompany,
                        draftId: this.form_state.draftId,
                        checkIds: this.form_state.checkIds,
                        name: this.form_state.name,
                        ngdwListInput: this.form_state.ngdwListInput,
                        fileIds: this.form_state.fileIds,
                        attach: this.form_state.attach,
                    }
                    this.form_state = { ...values, ...params }
                    if (!this.form_state.name) return
                    if (params.draftCompany?.length < 1 && !params.ngdwListInput) return
                    if (params.draftId?.length < 1) return
                    if (params.checkIds?.length < 1) return
                    this.subLoad = true
                    const obj = {
                        name: this.form_state.name || '发文稿纸',
                        urgencyDegree: Number(this.form_state.jjcd) || '',
                        secrecyDegree: Number(this.form_state.miji) || '',
                        // fileType: Number(values.xzfn) || '',
                        documentNumber: this.form_state.documentNumber,
                        outDraftDesc: this.form_state.outDraftDesc,
                        outDraftCompany: this.form_state.outDraftCompany,
                        meetCompany: this.form_state.meetCompany,
                        meetDesc: this.form_state.meetDesc,
                        title: this.form_state?.wgbt,
                        documentType: 2,
                        nodeParam: {
                            templateId: this.selectTemplateId,
                            processesList: arr,
                        },
                        summary: this.form_state.summary,
                        deviseOpinion: this.form_state.nbyj,
                        draftId: filter(this.form_state.draftId),
                        checkIds: filter(this.form_state.checkIds),
                        draftCompany:
                            getCorpName(this.form_state.ngdwListInput) || filter(this.form_state.draftCompany),
                        sendIds: filter(csr_list), // 抄送人
                        mainIds: filter(mainList), // 主送人
                        tellPhone: this.form_state?.tellPhone,
                        attach: this.form_state?.attach && this.form_state?.attach[0], // 正文
                        fileIds: this.form_state?.fileIds, // 附件
                        status,
                    }
                    //
                    if (id) {
                        obj.id = id
                    }
                    func(this.delEmptKey(obj))
                        .then((res) => {
                            if (!res.success) {
                                return
                            }
                            this.$message.success(res.message)
                            this.resetMess()
                            this.$emit('close')
                            //
                        })
                        .finally(() => {
                            this.subLoad = false
                        })
                } else {
                    this.$message.error('表单填写未完成，请继续填写')
                }
            })
        },
        choosePerson(list, index) {
            let val
            if (list === 'list') {
                val = [...this[list][index].val]
            } else {
                val = [...this[list]]
            }
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
                isList,
            } = getUserListMsg(list, index, val)
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
                type: this.type, // 1 部门 其他 员工
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    let isUserNode =
                        this.confineNodeSelectByChoosePerson && this.confineNodeSelectByChoosePerson(result, this[list])
                    if (isList) {
                        isUserNode && this.$set(this[list][index], 'val', [...result, ...resPart])
                    } else {
                        isUserNode && this.$set(this, list, [...result, ...resPart])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'departmentId' : 'userId'
        },
        // 处理通讯录数据
        filter(ids) {
            return (
                ids &&
                JSON.stringify(
                    ids.map(({ corpId, userId, id, type, corpName }) => ({
                        [this.getIdKey(type)]: userId || id,
                        corpId,
                        type,
                        corpName: corpName || '',
                    }))
                )
            )
        },
        // 处理节点数据
        handlerNodeList() {
            const arr = []
            arr.push({
                name: '接收人',
                sort: 99,
                userList: this.mainList.map((j) => ({
                    userId: j.userId,
                    corpId: j.corpId,
                    status: 0,
                })),
            })
            arr.push({
                name: '抄送人',
                sort: 100,
                userList: this.csr_list.map((j) => ({
                    userId: j.userId,
                    corpId: j.corpId,
                    status: 0,
                })),
            })
            return arr
        },
        formatDraftId(id) {
            if (!id) return []
            return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
        },
    },
}
</script>

<style lang="less" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}
.flex0 {
    display: flex;
    align-items: center;
}
.font {
    font-size: 16px;
}

.my-form {
    // width: 478px;
    margin-left: 10px;
    background: #fff;
    padding: 20px 0;
    ::v-deep .ant-calendar-picker {
        width: 100%;
    }
}
::v-deep .ant-form-item-label {
    text-align: left;
}

.line {
    height: 1px;
    border: 1px dashed #3b9afa;
    display: block;
    width: 100%;
    margin: 30px 0;
}
.tip {
    display: flex;
    font-size: 12px;
    color: #b9b9b9;
}
.plus-bt {
    width: 46px;
    height: 46px;
    background: #e9f2fe;
    border-radius: 4px;
    margin: 10px 0;
}
.left-line::before {
    content: '';
    height: 80px;
    position: relative;
    top: 0px;
    left: 13px;
    width: 2px;
    background: #cdcfd1;
}
.left-line-last:before {
    background: white;
}
.sp-icon {
    width: 26px;
    height: 26px;
    background: #cdcfd1;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: white;
}
.submit-bt {
    text-align: right;
    margin: 40px 0 40px;
    button {
        // width: 100px;
        height: 36px;
        background: #3b9afa;
        border-radius: 4px;
        margin-right: 10px;
    }
}
.edit-icon {
    margin-left: 10px;
}
.zusi {
    font-size: 12px;
    color: #acacac;
}
.input-dom {
    width: 100px;
    margin-left: 6px;
}
.input-dom-edit {
    border: none;
}
.input-dom-edit:hover {
    border: none;
}
.input-dom-edit:focus {
    border: none;
}
.tags {
    height: 46px;
    background: #f2f2f3;
    border: none;
    margin-bottom: 10px;
    button {
        margin: 0;
    }
}
.flex-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.tag-box {
    margin: 10px 0 10px 30px;
}
.arrow-right {
    font-size: 0.6em;
    color: #aaa;
    margin-right: 10px;
}
::v-deep .ant-form-extra {
    margin-top: 5px;
}
.moreTemp {
    cursor: pointer;
    color: #3b9afa;
}
</style>
