1<template>
  <div class="body">
    <div class="zwnr">
      <div class="flex title">
        <div class="fwxx">发文信息</div>
      </div>
      <div class="line"></div>
      <div style="margin-top: 20px">
        <a-form :form="form">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-item label="公文标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{ formMessage.biaoti }}
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="发文字号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{ formMessage.documentNumber }}
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="公文密级程度" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{ formatSelectValue(flow_secrecy_degree, formMessage.miji) }}
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="公文紧急程度" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{ formatSelectValue(flow_urgency_degree, formMessage.jjcd) }}
              </a-form-item>
            </a-col>

          

            <a-col :span="12">
              <a-form-item label="选择分类" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{ formatSelectValue(flow_file_type, formMessage.xzfn) }}
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="外部会稿单位/备注" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                {{ formMessage.outDraftDesc }}
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="拟稿单位/人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input
                  v-show="false"
                  v-decorator="['ngdw_list', {}]"
                />
                <div class="">
                  <div v-for="(o, i) in ngdw_list" :key="'ngdw_list' + i" class="my-item">
                    <a-tag class="tags flex0" color="blue">
                      <OpenData :type="o.type" :openid="o.userId|| o.departmentId" :corpid="o.corpId"></OpenData>
                    </a-tag>
                  </div>
                </div>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="外部会稿单位/人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input
                  v-show="false"
                  v-decorator="['wbhgdw_list', {}]"
                />
                <div class="">
                  <div v-for="(o, i) in wbhgdw_list" :key="'wbhgdw_list' + i" class="my-item">
                    <a-tag class="tags flex0" color="blue">
                      <OpenData :type="o.type" :openid="o.userId|| o.departmentId" :corpid="o.corpId"></OpenData>
                    </a-tag>
                  </div>
                </div>
              </a-form-item>
            </a-col>           

            <a-col :span="12">
              <a-form-item label="会稿单位/人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input
                  v-show="false"
                  v-decorator="['hgdw_list', {}]"
                />
                <div class="">
                  <div v-for="(o, i) in hgdw_list" :key="'hgdw_list' + i" class="my-item">
                    <a-tag class="tags flex0" color="blue">
                      <OpenData :type="o.type" :openid="o.userId|| o.departmentId" :corpid="o.corpId"></OpenData>
                    </a-tag>
                  </div>
                </div>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="核稿人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input
                  v-show="false"
                  v-decorator="['checkIds', {}]"
                />
                <div class="">
                  <div v-for="(o, i) in checkIds" :key="'checkIds' + i" class="my-item">
                    <a-tag class="tags flex0" color="blue">
                      <OpenData :type="o.type" :openid="o.userId|| o.departmentId" :corpid="o.corpId"></OpenData>
                    </a-tag>
                  </div>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="主送" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input
                  v-show="false"
                  v-decorator="['mainIds', {}]"
                />
                <div class="">
                  <div v-for="(o, i) in mainIds" :key="'mainIds' + i" class="my-item">
                    <a-tag class="tags flex0" color="blue">
                      <OpenData :type="o.type" :openid="o.userId|| o.departmentId" :corpid="o.corpId"></OpenData>
                    </a-tag>
                  </div>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="抄送" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input
                  v-show="false"
                  v-decorator="['sendIds', {}]"
                />
                <div class="">
                  <div v-for="(o, i) in sendIds" :key="'sendIds' + i" class="my-item">
                    <a-tag class="tags flex0" color="blue">
                      <OpenData :type="o.type" :openid="o.userId|| o.departmentId" :corpid="o.corpId"></OpenData>
                    </a-tag>
                  </div>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
    <!-- <div class="bg-gray"></div>
    <div class="zwnr">
      <div class="flex title">
        <div class="fwxx">正文内容</div>
      </div>
      <div class="line" style="margin-bottom: 23px"></div>
      <div class="content" v-html="formMessage.content"></div>
      <editor :content.sync="content" style="margin-bottom: 20px" ref="editor" />
    </div> -->

    <div class="bg-gray"></div>
    <div class="zwnr">
      <div class="flex title">
        <div class="fwxx">附件内容</div>
      </div>
      <div class="line"></div>
      <div>
        <a-row>
          <a-col :span="12">
            <div style="margin-top: 10px;">
              <span>已预览附件人员：</span>
              <a-tag color="pink" v-for="(item,index) in seeStatusIds" :key="index" style="margin-left: 10px;">
                <OpenData type="userName" :openid="item.userId"></OpenData>
              </a-tag>
            </div>
            <div style="margin-top: 10px;">
              <span>已下载附件人员：</span>
              <a-tag color="pink" v-for="(item,index) in downloadIds" :key="index" style="margin-left: 10px;">
                <OpenData type="userName" :openid="item.userId"></OpenData>
              </a-tag>
            </div>
            <div style="margin-top: 10px;">
              <span>已接受文件人员：</span>
              <a-tag color="pink" v-for="(item,index) in acceptIds" :key="index" style="margin-left: 10px;">
                <OpenData type="userName" :openid="item.userId"></OpenData>
              </a-tag>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="finish">
              <img src="/images/finish.svg" alt="finish" v-if="approvalStatus === 2">
              <img src="/images/rejected.svg" alt="rejected" v-else-if="approvalStatus === 3">
              <img src="/images/going.svg" alt="going" v-else>
            </div>
          </a-col>
        </a-row>

      </div>
      <div class="margin-tt">
        <upload :officialId="officialId" :isOnlyShow="true" :fileIds="fileIds" />
      </div>
    </div>
    <template>
      <div class="bg-gray"></div>
      <div class="footer">
        <a-button v-if="type==='accept'" type="primary" @click="acceptBill">接收公文</a-button>
        <a-popconfirm v-if="type!=='accept' && this.userId === this.formMessage.userId" placement="topRight" title="确定提醒接收/预览/下载吗？" @confirm="() => remindPeople()">
          <a-button type="primary">提醒接收/预览/下载 </a-button>
        </a-popconfirm>
      </div>
    </template>
  </div>
</template>

<script>
// eslint-disable-next-line camelcase
import { vali_exist } from '@/utils/validator'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import editor from '@/views/fileStream/documentManage/editor.vue'
import upload from '@/views/fileStream/documentManage/upload.vue'
import { documentBillDetail, acceptDocumentBill } from '@/api/modular/fileStream/documentManage'
import { remindPeopleApi } from '@/api/modular/fileStream/documentSend'
import userOne from '@/views/fileStream/documentManage/weixin/user_one'
import user from '@/views/fileStream/documentManage/weixin/user'
import Vue from 'vue'
export default {
  components: { editor, upload, userOne, OpenData, user },
  props: {
    officialId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      labelCol: {
        sm: { span: 5 }
      },
      wrapperCol: {
        sm: { span: 19 }
      },
      approvalStatus: -1,
      form: this.$form.createForm(this),
      formMessage: {},
      flow_secrecy_degree: [],
      flow_urgency_degree: [],
      flow_file_type: [],
      fileIds: [],
      content: '',
      ngdw_list: [],
      hgdw_list: [],
      wbhgdw_list: [],
      checkIds: [],
      mainIds: [],
      sendIds: [],
      handler: vali_exist,
      fileName: [],
      userId: '',
      downloadIds:[], // 下载人员
      acceptIds:[], // 接受人员
      seeStatusIds:[] // 查看人员
    }
  },
  watch: {
    ngdw_list(v) {
      this.form.setFieldsValue({ ngdw_list: v.toString() })
    },
    hgdw_list(v) {
      this.form.setFieldsValue({ hgdw_list: v.toString() })
    },
    wbhgdw_list(v) {
      this.form.setFieldsValue({ wbhgdw_list: v.toString() })
    },
    checkIds(v) {
      this.form.setFieldsValue({ checkIds: v.toString() })
    },
    mainIds(v) {
      this.form.setFieldsValue({ mainIds: v.toString() })
    },
    sendIds(v) {
      this.form.setFieldsValue({ sendIds: v.toString() })
    },
    officialId() {
      this.get_info()
    }
  },
  mounted() {
    this.get_info()
    if (Vue.ls.get('WX_data_set')) {
      this.userId = Vue.ls.get('WX_data_set').userId
    }
  },
  created() {
    this.getDict()
  },
  methods: {
    formatSelectValue(array, value) {
      const resValue = array.find(j => j.code === value)?.value
      return resValue || ''
    },
    get_info() {
      this.form_id = this.officialId
      const id = this.form_id
      if (id) {
        documentBillDetail({ id }).then((res) => {
          console.log('res.data', res.data)
          // eslint-disable-next-line camelcase
          const { get_arr } = this
          const {
            documentNumber,
            outDraftDesc,
            name,
            urgencyDegree,
            secrecyDegree,
            draftId,
            fileType,
            documentFileList,
            content,
            meetId,
            outDraftId,
            approvalStatus
          } = res.data
          const form = {
            biaoti: name,
            jjcd: String(urgencyDegree),
            miji: String(secrecyDegree),
            userId: draftId,
            xzfn: String(fileType),
            fileIds: documentFileList,
            content,
            hgdw_list: meetId,
            wbhgdw_list: outDraftId,
            checkIds: res.data.checkIds,
            mainIds: res.data.mainIds,
            sendIds: res.data.sendIds,
            documentNumber,
            outDraftDesc
          }
          this.approvalStatus = approvalStatus
          //
          this.form.setFieldsValue(form)
          this.formMessage = form
          //
          this.ngdw_list = get_arr(draftId)
          this.hgdw_list = get_arr(meetId)
          this.wbhgdw_list = get_arr(outDraftId)
          this.checkIds = get_arr(res.data.checkIds)
          this.mainIds = get_arr(res.data.mainIds)
          this.sendIds = get_arr(res.data.sendIds)
          this.downloadIds = []
          this.acceptIds = []
          this.seeStatusIds = []
          res.data.acceptList.forEach((item)=>{
            if(item.acceptStatus == 1){
              this.acceptIds.push(item)
            }
            if(item.downloadStatus == 1){
              this.downloadIds.push(item)
            }
            if(item.seeStatus == 1){
              this.seeStatusIds.push(item)
            }
          })
          //
          this.fileIds = documentFileList
        })
      }
    },
    /**
     * 接收公文
     */
    acceptBill() {
      acceptDocumentBill(this.officialId)
      .then(res => {
        if (res.code === 200) {
          this.$message.success('接收成功')
          this.$router.go(-1)
        }
      })
      .catch(err => {
        console.log(err)
      })
    },
    /**
     * 获取字典数据
     */
    getDict() {
      sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
        this.flow_secrecy_degree = res.data
      })
      sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
        this.flow_urgency_degree = res.data
      })
      sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
        this.flow_file_type = res.data
      })
    },

    clear_form() {
      this.form.resetFields()
      this.ngdw_list = []
      this.hgdw_list = []
      this.wbhgdw_list = []
      this.checkIds = []
      this.mainIds = []
      this.sendIds = []
    },
    empty() {
      this.$refs.upload.empty()
    },
    get_arr(ids) {
      if (!ids) return []
      return JSON.parse(ids)
    },
    /**  提醒附件的各种操作  */
    async remindPeople() {
      const id = this.form_id
      try {
        const res = await remindPeopleApi({ id, name: this.formMessage.biaoti, documentNumber: this.formMessage.documentNumber })
        if (res.code === 200) {
          this.$message.success('提醒发送成功')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.flex0 {
  display: flex;
  align-items: center;
}
.font {
  font-size: 16px;
}
.body {
  width: 1280px;
  margin: auto;
  background: #fff;

  .title {
    margin-top: 30px;
  }
  .line {
    height: 1px;
    border: 1px dashed #3b9afa;
    display: block;
    width: 100%;
    margin-top: 10px;
  }
  .fwxx {
    font-size: 18px;
    font-weight: bold;
    color: #3e3d3d;
  }
  .fwxx::before {
    content: '';
    width: 4px;
    height: 20px;
    background: #3b9afa;
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 10px;
  }
}
::v-deep .ant-form-item-label {
  text-align: left;
}
.bg-gray {
  height: 10px;
  background: #ebedf0;
}
.zwnr {
  padding: 0 37px;
}
.schj {
  width: 310px;
  height: 58px;
  background: #f9f9f9;
  border: 1px solid #e6e6e6;
}
.margin-tt {
  margin-top: 20px;
}
.bts {
  text-align: center;
  padding: 57px 0 64px 0;
  button {
    font-size: 16px;
    margin: 0 10px;
  }
}
.my-item {
  display: inline-block;
}
.footer {
  padding: 24px;
  text-align: left;
}
.finish {
  text-align: right;
  line-height: 96px;
  height: 96px;
  img {
    width: 86px;
  }
}
</style>
