<template>
  <full-model-view progress-dot :visible="visible" :title="title" :sub-title="subTitle" @cancel="handleCancel">
    <div>
      <a-row :gutter="20">
        <a-col :lg="16" :xl="16" :md="24">
          <!-- <billTable :formObj="formMessage"></billTable> -->
          <fastTable v-if="type == 2" :formObj="formMessage"></fastTable>
          <billTable v-else :formObj="formMessage"></billTable>
        </a-col>
        <a-col :lg="8" :xl="8" :md="24">
          <fastNodes v-if="type == 2" :formMessage="formMessage" :todoType="todo"></fastNodes>
          <billNodes v-else :formMessage="formMessage"></billNodes>
        </a-col>
      </a-row>
    </div>
  </full-model-view>
</template>

<script>
import { documentBillDetail } from '@/api/modular/fileStream/documentManage'
import { FullModelView } from '@/components'
import billNodes from './components/billNodes.vue'
import billTable from './components/billTable.vue'
import fastNodes from './components/fastNodes.vue'
import fastTable from './components/fastTable.vue'
export default {
  name: 'StepsLabel',
  components: {
    FullModelView,
    billNodes,
    billTable,
    fastTable,
    fastNodes,
  },
  data() {
    return {
      title: '详情',
      subTitle: '',
      visible: false,
      officialId: '',
      submitLoading: false,
      type: '',
      formMessage: {},
      todo: '',
    }
  },
  beforeDestroy() {
    this.visible = false
  },
  methods: {
    open({ type, id, todo }) {
      console.log('id++++++++', id)
      this.officialId = id
      this.visible = true
      this.type = type
      this.todo = todo
      this.getInfo(id)
    },
    getInfo(id) {
      this.form_id = this.officialId
      // const id = this.form_id
      if (id) {
        documentBillDetail({ id }).then((res) => {
          console.log('res.data', res.data)
          const {
            documentNumber,

            name,
            urgencyDegree,
            secrecyDegree,
            draftId,
            draftCompany,
            templateName,
            tellPhone,
            recipientStatus,
            fileType,
            documentFileList,
            documentNodeList,
            content,
            meetId,
            title,
            outDraftId,
            deviseOpinion,
            approvalStatus,
            mainFile,
            meetCompany,
            meetDesc,
            outDraftCompany,
            outDraftDesc,
            summary,
          } = res.data
          const form = {
            attach: mainFile && [mainFile], //正文
            meetCompany,
            meetDesc,
            outDraftCompany,
            name,
            approvalStatus,
            recipientStatus,
            jjcd: urgencyDegree,
            miji: secrecyDegree,
            userId: draftId,
            draftId,
            summary,
            mainFile,
            templateName,
            draftCompany,
            xzfn: String(fileType),
            fileIds: documentFileList,
            content,
            title,
            deviseOpinion,
            tellPhone,
            documentNodeList,
            hgdw_list: meetId,
            wbhgdw_list: outDraftId,
            checkIds: res.data.checkIds,
            mainIds: res.data.mainIds,
            sendIds: res.data.sendIds,
            documentNumber,
            outDraftDesc,
          }
          this.approvalStatus = approvalStatus
          //
          this.formMessage = form
          this.downloadIds = []
          this.acceptIds = []
          this.seeStatusIds = []
          //
          this.fileIds = documentFileList
        })
      }
    },
    close() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
      if (this.$route.query.id) {
        this.$router.go(-1)
      }
    },
  },
}
</script>

<style scoped>
.add-steps-box {
  /*width: 1200px;*/
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0px 160px;
  padding-bottom: 20px;
}
.add-content-box {
  margin: 0 auto;
  /* width: 1200px; */
  padding: 0px 160px;
  box-sizing: border-box;
}
.add-content-block {
  margin: 0 auto;
}
.add-steps-actions {
  width: 400px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
}
</style>
