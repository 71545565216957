1<template>
    <div class="body box" ref="ygh-content-box" id="ygh-content-box">
        <div class="comp">
            <div class="box-btns">
                <a-button
                    :type="btck == i ? 'primary' : ''"
                    icon="file-sync"
                    class="btns"
                    v-for="(o, i) in btna"
                    :key="i"
                    @click="change_status(i)"
                    >{{ o }}</a-button
                >
            </div>
        </div>
        <mytable
            @preview="(evt) => $refs.DrawerLabel.open(evt)"
            ref="mytable"
            @showDraw="(evt) => $refs.StepForm.open(evt)"
            @stepFormFuchsia="(evt) => $refs.StepFormFuchsia.open(evt)"
        />
        <StepForm @refresh="$refs.mytable.refresh()" ref="StepForm" />
        <!-- 套红组件  -->
        <stepFormFuchsia @refresh="$refs.mytable.refresh()" ref="StepFormFuchsia" />
        <!-- <DrawerLabel ref="DrawerLabel" /> -->
        <draftDetail ref="DrawerLabel" />
        <!-- <myform v-if="change == 0" /> -->
    </div>
</template>

<script>
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import draftDetail from './draftDetail.vue'
import DrawerLabel from './drawerLabel.vue'
import myform from './form.vue'
import StepForm from './stepForm.vue'
import stepFormFuchsia from './stepFormFuchsia.vue'
import mytable from './table.vue'
export default {
    components: { mytable, myform, StepForm, stepFormFuchsia, DrawerLabel, draftDetail },
    data() {
        return {
            btck: 0,
            btna: ['全部', '未完成', '已完成', '草稿箱'],
        }
    },
    watch: {
        '$route.query.id': {
            handler() {
                if (this.$route.query.id) {
                    this.$nextTick(() => {
                        this.$refs.DrawerLabel.open({
                            id: this.$route.query.id,
                            type: this.$route.query.type ? this.$route.query.type : '',
                            todo: this.$route.query.todo ? this.$route.query.todo : '',
                        })
                    })
                }
            },
            immediate: true,
        },
    },
    created() {
        init_wx()
    },
    methods: {
        gwfs() {
            this.btck = 0
            this.$router.replace({ path: '/fileStreamDocumentSend' })
        },
        change_status(i) {
            this.btck = i
            const active = i === 3 ? 1 : 2
            this.$refs.mytable.query({ approvalTab: i, active, index: i })
        },
    },
}
</script>
<style lang="less" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}
.font {
    font-size: 16px;
}

.body {
    position: relative;
    margin: auto;
    background: #fff;
}
.comp {
    padding: 33px 40px 0;
}
.bts {
    margin-left: 17px;
}
.btns {
    margin-right: 17px;
    font-size: 16px;
}
.fred {
    color: #f56c6c;
}
</style>
