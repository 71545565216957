1<template>
    <div class="box">
        <div class="big">
            <div>
                <a-card :bordered="false">
                    <s-table
                        ref="table"
                        :columns="columns"
                        :data="loadData"
                        :alert="false"
                        :rowKey="(record) => record.id"
                    >
                        <span slot="operator">
                            <div class="flex" style="margin: 0; align-items: center">
                                <div>
                                    <a-input-search
                                        placeholder="请输入关键字"
                                        @search="onSearch"
                                        class="search"
                                        v-model="queryParam.name"
                                    >
                                        <a-button slot="enterButton" icon="search"> </a-button>
                                    </a-input-search>
                                </div>
                                <div class="btns">
                                    <a-button
                                        style="background: none; color: #40a9ff"
                                        type="primary"
                                        shape="round"
                                        @click="$emit('showDraw', { type: 'add', documentType: 2 })"
                                        icon="plus"
                                    >
                                        快速发文
                                    </a-button>

                                    <a-button
                                        style="background: none; color: #40a9ff; margin-left: 15px"
                                        type="primary"
                                        shape="round"
                                        @click="$emit('showDraw', { type: 'add', documentType: 3 })"
                                        icon="plus"
                                    >
                                        拟稿发文
                                    </a-button>
                                    <!-- <a-button
                        style="background: none; color: #40a9ff; margin-left: 15px"
                        type="primary"
                        shape="round"
                        @click="$emit('stepFormFuchsia', {})"
                        icon="plus"
                      >
                        发文套红
                      </a-button> -->
                                </div>
                            </div>
                        </span>
                        <span slot="draftId" slot-scope="text, record">
                            <div v-for="(o, i) in record.draftId" :key="i" class="my-item">
                                <a-tag class="tags flex0" color="blue">
                                    <OpenData
                                        :type="o.type"
                                        :openid="o.userId || o.departmentId"
                                        :corpid="o.corpId"
                                    ></OpenData>
                                </a-tag>
                            </div>
                        </span>
                        <span slot="mainIds" slot-scope="text, record">
                            <div v-for="(o, i) in record.mainIds" :key="i" class="my-item">
                                <a-tag class="tags flex0" color="blue">
                                    <OpenData
                                        :type="o.type"
                                        :openid="o.userId || o.departmentId"
                                        :corpid="o.corpId"
                                    ></OpenData>
                                </a-tag>
                            </div>
                        </span>
                        <span slot="sendIds" slot-scope="text, record">
                            <div v-for="(o, i) in record.sendIds" :key="i" class="my-item">
                                <a-tag class="tags flex0" color="blue">
                                    <OpenData
                                        :type="o.type"
                                        :openid="o.userId || o.departmentId"
                                        :corpid="o.corpId"
                                    ></OpenData>
                                </a-tag>
                            </div>
                        </span>
                        <span slot="documentType" slot-scope="text">
                            <span v-if="text == +1">收文登记</span>
                            <span v-else-if="text == +2">快速发文</span>
                            <span v-else-if="text == +3">拟稿发文</span>
                        </span>
                        <span slot="createTime" slot-scope="text">
                            {{ text && text.substr(0, 10) }}
                        </span>
                        <span slot="urgencyDegree" slot-scope="text" :style="flowUrgencyDegree(text)">
                            {{ Filter(flow_urgency_degree, text) }}
                        </span>
                        <span slot="secrecyDegree" slot-scope="text" :style="flowSecrecyDegree(text)">
                            {{ Filter(flow_secrecy_degree, text) }}
                        </span>
                        <span slot="approvalStatus" slot-scope="text" :class="text != 2 ? 'fred' : ''">
                            {{ Filter(flow_approval_status, text) }}
                        </span>
                        <span class="noWrap" slot="action" slot-scope="text, record">
                            <a class="bts" @click="$emit('preview', { id: record.id, type: record.documentType })"
                                >查看</a
                            >
                            <!-- <a-popconfirm
                v-if="active === 2"
                placement="topRight"
                title="确定提醒接收/预览/下载吗？"
                @confirm="() => remindPeople(record)"
              >
                <a class="bts">提醒收文</a>
              </a-popconfirm> -->
                            <template v-if="[3].includes(+record.approvalStatus)">
                                <a-popconfirm
                                    placement="topRight"
                                    title="审批流程将从第一步开始进行审批"
                                    @confirm="edit({ ...record, editType: 'retrial' })"
                                >
                                    <a class="bts">修改重审</a>
                                </a-popconfirm>

                                <!-- <a-popconfirm
                  placement="topRight"
                  title="审批流程将从驳回人这重新开始继续审批"
                  @confirm="edit({ ...record, editType: 'renewal' })"
                >
                  <a class="bts">修改续审</a>
                </a-popconfirm> -->
                            </template>
                            <template v-if="record.approvalStatus === 6">
                                <a class="bts" @click="edit(record)">修改</a>
                                <a-popconfirm
                                    placement="topRight"
                                    title="确定删除公文？"
                                    @confirm="() => delDraft(record)"
                                >
                                    <a class="bts">删除</a>
                                </a-popconfirm>
                            </template>
                            <a
                                class="bts"
                                v-if="record.approvalStatus === 2 && record.documentType === 3 && !record.nooseStatus"
                                @click="$emit('stepFormFuchsia', record)"
                                >套红</a
                            >
                            <a class="bts" v-if="judgeIsShowDeal(record)" @click="deal(record)">{{
                                record.approvalStatus === 4 ? '处理公文审批' : '公文下发'
                            }}</a>
                            <!-- <a class="bts">签收</a> -->
                        </span>
                    </s-table>
                </a-card>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line camelcase
import { delDocumentBill, documentBillDispatchPage } from '@/api/modular/fileStream/documentManage'
import { remindPeopleApi } from '@/api/modular/fileStream/documentSend'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'
import { stateColor } from '@/mixins/index.js'
import { handleAppointEleHeight } from '@/utils/common.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import { getDefaultColumns } from './config'
export default {
    name: 'list',
    components: {
        STable,
        OpenData,
    },
    mixins: [stateColor],
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            btck: 0,
            // 查询参数
            queryParam: { documentType: 2, approvalTab: 0 },
            active: 2,
            currentActive: 0,
            // 表头
            columns: [],
            // 加载数据方法 必须为 Promise 对象
            loadData: (parameter) => {
                const { approvalStatus } = parameter
                let params = { ...parameter }
                if (approvalStatus && approvalStatus.length > 0)
                    params = { ...parameter, approvalStatus: approvalStatus[0] }
                return documentBillDispatchPage(Object.assign(params, this.queryParam, { status: this.active }))
                    .then((res) => {
                        const data = { ...res.data }
                        data.rows = data.rows.map((ele) => {
                            ele.draftId = formatDraftId(ele.draftId)
                            ele.mainIds = formatDraftId(ele.mainIds)
                            ele.sendIds = formatDraftId(ele.sendIds)
                            return ele
                        })
                        handleAppointEleHeight('#ygh-content-box', ['.comp', '.s-table-tool'], 66, res)
                        return data
                    })
                    .catch((err) => {
                        return {
                            pageNo: 1,
                            pageSize: 10,
                            rainbow: [],
                            rows: [],
                            totalPage: 0,
                            totalRows: 0,
                        }
                    })
            },
            selectedRowKeys: [],
            selectedRows: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
        }
    },
    created() {
        this.getDict()
        init_wx()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.comp', '.s-table-tool'], 66)
    },
    methods: {
        /**  提醒附件的各种操作  */
        async remindPeople({ id, name, documentNumber }) {
            try {
                const res = await remindPeopleApi({ id, name, documentNumber })
                if (res.code === 200) {
                    this.$message.success('提醒发送成功')
                }
            } catch (error) {
                console.log(error)
            }
        },
        judgeIsShowDeal({ approvalStatus, recipientStatus, documentType }) {
            return [2, 4].includes(approvalStatus) && recipientStatus !== 1 && documentType === 3
        },
        deal(record) {
            this.$router.push({ path: '/fileStream_Approval_process', query: { id: record.id, todo: 'dealWith' } })
        },
        formatStatus(text) {
            switch (Number(text)) {
                case 1:
                    return '草稿'
                case 2:
                    return '已发布'
                case 3:
                    return '撤回'
                case 4:
                    return '删除'
            }
        },
        query({ approvalTab, active, index }) {
            this.active = active
            this.currentActive = index
            this.queryParam.approvalTab = approvalTab
            this.columns = getDefaultColumns(this.flow_approval_status, approvalTab === 0)
            this.refresh()
        },
        Filter(arr, s) {
            // eslint-disable-next-line eqeqeq
            const values = Array.isArray(arr) ? arr.filter((item) => item.code == s) : []
            if (values.length > 0) {
                return values[0].value
            }
        },
        delDraft({ id }) {
            delDocumentBill([
                {
                    id,
                },
            ])
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('删除成功')
                        this.refresh()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        refresh() {
            this.$refs.table.refresh()
        },
        /**
         * 获取字典数据
         */
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
            sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
                this.flow_approval_status = res.data
                this.columns = getDefaultColumns(res.data)
            })
        },
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        edit(record) {
            this.$emit('showDraw', {
                type: 'edit',
                id: record.id,
                editType: record.editType,
                documentType: record.documentType,
            })
        },
    },
}
</script>

<style lang="less" scoped>
.noWrap {
    white-space: nowrap;
}
.box {
    padding: 15px 39px 33px;
    height: calc(100% - 17px - 24px);
    .big {
        button {
            font-size: 16px;
        }
    }
    .flex {
        display: flex;
        justify-content: space-between;
        margin: 30px 0 20px 0;
    }
    .search {
        width: 300px;
        border-radius: 4px;
    }
}
::v-deep .ant-table-pagination.ant-pagination {
    text-align: center;
    float: none;
}
::v-deep .ant-card-body {
    padding: 0;
}
.btns {
    margin-right: 17px;
    display: flex;
}
.fred {
    color: #f56c6c;
}
.bts {
    margin-right: 10px;
}
.flex0 {
    display: flex;
    align-items: center;
}
.my-item {
    display: inline-block;
    .tags {
        margin-bottom: 1px;
    }
}
</style>
