<template>
  <full-model-view :destroyOnClose="true" progress-dot :visible="visible" :title="title" :sub-title="subTitle" @cancel="handleCancel">
    <div slot="up-extra"></div>
    <div v-if="+documentType == 3">
      <!-- 拟稿发文 -->
      <form-box
        :type="type"
        @changeStatus="changeStatus"
        :randomCode="randomCode"
        :officialId="officialId"
        :editType="editType"
        @close="close"
        ref="box"
      />
    </div>
    <div v-if="+documentType == 2">
      <!-- 快速发文 -->
      <fastFromBox ref="MyForm" @close="close" :editType="editType" :randomCode="randomCode" :officialId="officialId" />
    </div>
  </full-model-view>
</template>

<script>
import { Ellipsis, FullModelView } from '@/components'
import { mapState } from 'vuex'
import fastFromBox from './fastFromBox.vue'
import MyForm from './form.vue'
import formBox from './fromBox.vue'
export default {
  name: 'StepsForm',
  components: {
    Ellipsis,
    FullModelView,
    MyForm,
    formBox,
    fastFromBox,
  },
  computed: {
    ...mapState({
      visible: (state) => state.bill.postMsg,
    }),
  },
  watch: {
    visible: {
      handler(val) {
        const { todo } = this.$route.query
        if (val && todo === 'draftDocument') {
          console.log("sdfsdfsdfsd====")
          this.type = 'draft'
          this.title = '拟稿发文'
          this.$router.push({})
          this.open({ type: 'draft', documentType: 3 })
        }else if(val && todo === 'draftDocumentdeeee'){
          this.title = '快速发文'
          this.$router.push({})
          this.open({ type: 'draft', documentType: 2 })
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      title: '新增',
      subTitle: '公文发送',
      // visible: false,
      officialId: '',
      submitLoading: false,
      type: '',
      randomCode: 0,
      editType: '', //修改类型
      documentType: '', // 发文类型
    }
  },
  beforeDestroy() {
    this.visible && this.$store.commit('bill/setPostMsg', false)
  },
  methods: {
    open({ type, id, documentType, editType }) {
      this.officialId = ''
      this.editType = editType
      this.type = type
      this.documentType = documentType
      console.log('_____________', this.type)
      if (type === 'edit') {
        this.officialId = id
        this.randomCode = Math.floor(Math.random() * 1024 * 1024 * 1024)
        this.title = '编辑'
        // 重审
        if (this.editType == 'retrial') {
          this.title = '修改重审'
        }
        // 续审
        if (this.editType == 'renewal') {
          this.title = '修改续审'
        }
      } else {
        this.title = '新增'
        this.officialId = ''
        this.$refs.MyForm && this.$refs.MyForm.resetDetail()
      }
      this.$store.commit('bill/setPostMsg', true)
    },
    close() {
      this.$store.commit('bill/setPostMsg', false)
      this.$emit('refresh')
    },
    handleCancel() {
      this.$store.commit('bill/setPostMsg', false)
      this.$emit('ok')
    },
    changeStatus() {},
  },
}
</script>

<style scoped>
.add-steps-box {
  /*width: 1200px;*/
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0px 160px;
  padding-bottom: 20px;
}
.add-content-box {
  margin: 0 auto;
  /* width: 1200px; */
  padding: 0px 160px;
  box-sizing: border-box;
}
.add-content-block {
  margin: 0 auto;
}
.add-steps-actions {
  width: 400px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
}
</style>
