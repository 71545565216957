<template>
  <full-model-view progress-dot :visible="visible" :title="title" :sub-title="subTitle" @cancel="handleCancel">
    <a-spin tip="提交中..." :spinning="confirmLoading">
      <div slot="up-extra"></div>
      <div>
        <div style="display: flex; padding-bottom: 10px">
          <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="inline">
            <a-form-model-item label="模板名称" prop="name">
              <a-select v-model="form.name" style="width: 300px" @change="selectHead">
                <div slot="dropdownRender" slot-scope="menu">
                  <v-nodes :vnodes="menu" />
                  <!-- <a-divider style="margin: 4px 0;" />
                  <div
                    style="padding: 4px 8px; cursor: pointer;"
                    @mousedown="e => e.preventDefault()"
                    @click="getDocumentHeadList"
                  >
                    <a-icon type="plus" /> 获取更多
                  </div> -->
                </div>
                <a-select-option v-for="item in HeadList" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="发文年限" prop="time">
              <a-input @change="handleChangeYear" style="width: 100px" v-model="form.time" :maxLength="4" />
            </a-form-model-item>
            <a-form-model-item label="字号" prop="serial">
              <a-input @change="handleChangeYear" v-model="form.serial"></a-input>
            </a-form-model-item>
          </a-form-model>
          <div style="display: flex; align-items: center">
            <div style="display: flex; align-items: center; margin-left: 20px">
              <a-popconfirm placement="topRight" title="确定套红？" @confirm="submitForm">
                <a-button type="primary" :loading="confirmLoading"> 确定 </a-button>
              </a-popconfirm>
            </div>
            <div style="margin-left: 20px; color: #ccc">提示: 确定套红之后将生成正文附件，套红之后可进行公文下发。</div>
          </div>
        </div>
        <div v-if="visible" style="width: 100%; margin-left: 10px">
          <editor :content.sync="content" ref="editor" />
        </div>
      </div>
    </a-spin>
  </full-model-view>
</template>

<script>
import {
  documentFormAddApi,
  documentFormEditApi,
  documentFormPageApi,
  toPdf,
} from '@/api/modular/fileStream/documentHead'
import { Ellipsis, FullModelView } from '@/components'
import editor from './editor'
export default {
  name: 'StepsForm',
  components: {
    Ellipsis,
    FullModelView,
    editor,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      title: '正文套红',
      type: 'add',
      subTitle: '正文套红',
      visible: false,
      billInfo: {},

      HeadList: [], // 模板列表

      content: '', // 富文本内容 （ 合成显示 ）
      mainContent: '', // 正文 内容
      headContent: '', // 红头  内容
      headContentOrigin: '',
      confirmLoading: false, // 提交loading
      form: {
        id: '',
        name: '',
        content: '',
        serial: '',
        time: '',
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: ['change', 'blur'] }],
        time: [
          { required: true, message: '请输入发文年限', trigger: ['change', 'blur'] },
          { validator: this.handleFuchsiaTime },
        ],
        serial: [{ required: true, message: '请输入字号', trigger: ['change', 'blur'] }],
      },
    }
  },
  methods: {
    handleChangeYear() {
      this.headContent = this.headContentOrigin
        .replace('year', this.form.time || 'year')
        .replace('serial', this.form.serial || 'serial')
      this.content = this.headContent + this.mainContent
    },
    open(data) {
      this.mainContent = data.content || ''
      this.visible = true
      this.billInfo = data
      this.getDocumentHeadList()
      this.content = this.mainContent
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
    handleFuchsiaTime(rule, value, callback) {
      let year = new Date().getFullYear()
      let reg = /^[0-9]*$/gi
      if (value && !reg.test(value)) {
        callback('请输入正确的年限')
      }
      if (value && value < '1970') {
        callback('输入年限不能小于1970')
      }
      if (value && value > year) {
        callback('输入年限不能大于当前年份')
      }
      callback()
    },
    /**
     * 获取模板列表
     */
    getDocumentHeadList() {
      documentFormPageApi({
        pageNo: 1,
        pageSize: 100,
      })
        .then((res) => {
          this.HeadList = [].concat(res.data.rows)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    close() {
      this.visible = false
      this.$emit('refresh')
    },
    handleCancel() {
      this.visible = false
      this.$emit('refresh')
    },
    resetContent() {
      this.form = Object.assign(
        {},
        {
          id: '',
          name: '',
          content: '',
        }
      )
    },
    /**
     * 选择对应的红头模板
     */
    selectHead(value, option) {
      console.log('value, option===', value, option)
      this.headContent = this.HeadList.find((item) => item.id === value)?.content
      this.headContentOrigin = this.headContent
      this.content = this.headContent + this.mainContent
    },
    /**
     * 提交表单校验
     */
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          toPdf({
            billId: this.billInfo.id,
            content: this.content,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success('套红成功')
              this.close()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 新增
    documentFormAddFun() {
      this.confirmLoading = true
      documentFormAddApi({ ...this.form })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message)
            this.handleCancel()
          } else {
            this.$message.error(res.message)
          }
          this.confirmLoading = false
        })
        .finally((res) => {
          this.confirmLoading = false
        })
    },
    // 编辑
    documentFormEditFun() {
      this.confirmLoading = true
      documentFormEditApi({ ...this.form })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message)
            this.handleCancel()
          } else {
            this.$message.error(res.message)
          }
          this.confirmLoading = false
        })
        .finally((res) => {
          this.confirmLoading = false
        })
    },
  },
}
</script>

<style scoped>
.add-steps-box {
  /*width: 1200px;*/
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0px 160px;
  padding-bottom: 20px;
}

.add-content-box {
  margin: 0 auto;
  /* width: 1200px; */
  padding: 0px 160px;
  box-sizing: border-box;
}

.add-content-block {
  margin: 0 auto;
}

.add-steps-actions {
  width: 400px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
}
</style>
