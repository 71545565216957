<template>
  <div class="billNodes">
    <process
      :documentNodeList="formMessage.documentNodeList"
      :approvalStatus="formMessage.approvalStatus"
      :recipientStatus="formMessage.recipientStatus"
      :templateName="formMessage.templateName"
    ></process>
  </div>
</template>
<script>
import process from '@/views/fileStream/documentSend/components/process.vue'
export default {
  components: {
    process,
  },
  props: {
    formMessage: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    nodesMess() {
      const nodesCopy = JSON.parse(JSON.stringify(this.nodes))
      nodesCopy.forEach((element) => {
        element.userList = element.nodes
      })
      return nodesCopy
    },
  },
}
</script>
<style lang="less" scoped>
.billNodes {
  padding: 56px 20px 10px;
  box-sizing: border-box;
}
</style>
