<template>
  <div class="fastNodes">
    <div class="processContainer">
      <a-steps direction="vertical" :current="stepIndex">
        <a-step v-for="(documentNode, index) in documentNodeListCopy" :key="documentNode.id">
          <!-- <template slot="icon">
            <a-icon type="user-add" />
          </template> -->
          <template slot="title">
            {{ documentNode.name }}
          </template>
          <template slot="description">
            <div v-if="documentNode.nodes.length">
              <div class="description-list flex" v-for="node in documentNode.nodes" :key="node.id">
                <template>
                  <div
                    class="name flex-1"
                    :class="{
                      check: node.approvalFlag === 1,
                      clock: node.approvalFlag !== 1 && node.approvalFlag !== 0,
                    }"
                  >
                    <a-tag :color="node.approvalFlag === 1 ? 'blue' : ''">
                      <OpenData
                        :type="getDataType(node)"
                        :openid="node.userId || node.deptId"
                        :corpid="node.corpId"
                      ></OpenData>
                    </a-tag>
                  </div>
                  <div
                    class="status flex-1"
                    :class="{
                      check: node.approvalFlag === 1 && node.status === 1,
                      clock: node.approvalFlag === 1 && node.status === 2,
                    }"
                  >
                    {{ formatApprovalStatus(node.approvalFlag, documentNode.sort, node.status) }}
                  </div>
                  <div class="date flex-1">{{ formatApprovalTime(node.approvalTime) }}</div>
                </template>
              </div>
            </div>
            <div v-else>
              <a-tag> 暂无{{ documentNode.name }}</a-tag>
            </div>
          </template>
        </a-step>
      </a-steps>
      <div class="footer" v-if="todoType == 'accept' && approvalFlag == 0">
        <a-button type="primary" @click="acceptBill">接收公文</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { acceptDocumentBill } from '@/api/modular/fileStream/documentManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
export default {
  components: {
    OpenData,
  },
  data() {
    return {
      nodes: [
        {
          name: '接收人',
          sort: 0,
        },
        {
          name: '抄送人',
          sort: 1,
        },
      ],
    }
  },
  props: {
    formMessage: {
      type: Object,
      default() {
        return {}
      },
    },
    todoType: {
      type: String,
      default: '',
    },
  },
  computed: {
    nodesMess() {
      const { mainIds, sendIds } = this.formMessage
      if (sendIds) {
        this.stepIndex = 1
      }
      let arr = [this.formatDraftId(mainIds), this.formatDraftId(sendIds)]
      const nodeCopy = this.nodes.map((node, index) => {
        return {
          ...node,
          nodes: arr[index],
        }
      })
      return nodeCopy
    },
    documentNodeListCopy() {
      const { documentNodeList } = this.formMessage
      return documentNodeList ? documentNodeList.slice() : []
    },
    approvalFlag() {
      return this.documentNodeListCopy
        .filter((item) => item.sort == 99)[0]
        ?.nodes.filter((item) => item?.userId == userId)[0]?.approvalFlag
    },
    stepIndex() {
      return this.documentNodeListCopy.findIndex((node) => node.nodeFlag === 0) || 0
    },
  },
  methods: {
    /**
     * 接收公文
     */
    acceptBill() {
      acceptDocumentBill(this.$route.query.id)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('接收成功')
            this.$nextTick(() => {
              this.$router.go(-1)
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    formatDraftId(id) {
      if (!id) return []
      return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
    },
    getDataType(node) {
      return node.deptId ? 'departmentName' : 'userName'
    },
    formatApprovalTime(approvalTime) {
      if (!approvalTime) return ''
      const date = new Date(approvalTime)
      const dateStr = date.toLocaleString()
      return dateStr.replace(/\//g, '-')
    },
    formatApprovalStatus(approvalFlag, sort, status) {
      if (sort === 99) {
        return approvalFlag ? '已接收' : '待接收'
      }
      if (sort === 100) {
        return approvalFlag ? '已通知' : '待通知'
      }
      if (approvalFlag === 0) {
        return '待审核'
      } else if (approvalFlag === 3) {
        return '同级已审'
      } else if (status === 1) {
        return '审核通过'
      } else {
        return '驳回'
      }
    },
  },
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.flex0 {
  display: flex;
}
.font {
  font-size: 16px;
}
.fastNodes {
  padding: 70px 20px 10px;
  box-sizing: border-box;
}
.processContainer {
  background: #fff;
  .templateName {
    font-size: 16px;
    color: #1890ff;
    margin-bottom: 10px;
  }
}
/deep/ .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #e9923d;
}
.description-list {
  align-items: center;
  justify-content: flex-start;
  .flex-1 {
    flex: 1;
  }
  .name {
    font-size: 14px;
    color: #000000;
    &.check {
      color: #52c41a;
    }
    &.clock {
      color: #f5222d;
    }
  }
  .status {
    text-align: center;
    &.check {
      color: #52c41a;
    }
    &.clock {
      color: #f5222d;
    }
  }
  .date {
    font-size: 12px;
    color: #3e3d3d;
    text-align: right;
  }
}
</style>
  