1<template>
  <div class="body">
    <a-spin :spinning="formLoading" tip="加载数据中...">
      <div class="info-title bdr" v-if="$route.query.id">
        <a-button type="link" icon="left" @click="$router.go(-1)" />{{ info.name }}
      </div>
      <div>
        <a-row :gutter="40">
          <a-col :lg="16" :xl="16" :md="24">
            <a-tabs v-model="active" class="left-com font bdr">
              <a-tab-pane :key="1" tab="拟稿单">
                <mytable
                  ref="mytable"
                  :editType="editType"
                  :info.sync="info"
                  :content="content"
                  @tanslateTab="tanslateTab"
                />
              </a-tab-pane>
              <a-tab-pane :key="2" tab="正文编辑">
                <editor :content.sync="content"></editor>
              </a-tab-pane>
            </a-tabs>
          </a-col>
          <a-col :lg="8" :xl="8" :md="24">
            <template v-if="editType == 'renewal'">
              <div class="process">
                <processMess
                  :templateName="templateName"
                  :approvalStatus="approvalStatus"
                  :recipientStatus="recipientStatus"
                  class="bdr"
                  :from="from"
                  :todoType="$route.query.todo"
                  :documentNodeList="documentNodeList"
                  :documentType="documentType"
                  :nooseStatus="nooseStatus"
                />
              </div>
              <div class="submit-bt">
                <a-popconfirm placement="topRight" title="确定重新开始审批吗？" @confirm="submitRenewalData">
                  <a-button type="primary" size="large"> 保存并发起续审 </a-button>
                </a-popconfirm>
              </div>
            </template>
            <template v-else>
              <myform
                @close="close"
                class="bdr"
                :tableMess="tabMess"
                :getFormObj="getFormObj"
                :randomCode="randomCode"
                :officialId="officialId"
                @setDetail="setDetail"
                @changeStatus="changeStatus"
                @setFormLoading="setFormLoading"
                :content.sync="content"
                :fileIds.sync="fileIds"
                ref="myform"
              />
            </template>
          </a-col>
        </a-row>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { sysFileInfoUpload } from '@/api/modular/system/fileManage'
import { AntdEditor } from '@/components'
import { retrial } from '@/views/fileStream/documentManage/minxins'
import { corpId, userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
import processMess from '@/views/fileStream/documentSend/components/process.vue'
import moment from 'moment'
import editor from '../documentManage/editor.vue'
import myform from './draft_from.vue'
import mytable from './draft_table.vue'
export default {
  components: { mytable, myform, AntdEditor, editor, processMess },
  props: {
    officialId: {
      type: String,
      default: '',
    },
    todoType: {
      type: String,
      default: 'registration',
    },
    randomCode: {
      type: Number,
      default: 0,
    },
    editType: {
      type: '',
      default: '',
    },
  },
  mixins: [retrial],
  data() {
    return {
      fileIds: [],
      content: '',
      tabMess: {},
      active: 1,
      info: {},
      form: this.$form.createForm(this),
      // 文本编辑器
      editorUploadConfig: {
        method: 'http',
        callback: this.editorUploadImage,
      },
      editorContent: '',
      editorContentText: '',
      moment,

      formLoading: false,
    }
  },
  methods: {
    changeEditor(html, ele) {
      this.editorContent = html
      this.editorContentText = ele.text()
    },
    handleChange(evt) {
      this.tabMess = evt
    },
    tanslateTab() {
      this.active = 2
    },
    getEditor(editor) {
      this.editor = editor
    },
    /**
     * 编辑器回调上传及回传图片url
     */
    editorUploadImage(files, insert) {
      const formData = new FormData()
      files.forEach((file) => {
        formData.append('file', file)
      })
      sysFileInfoUpload(formData).then((res) => {
        if (res.success) {
          insert(process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + res.data)
        } else {
          this.$message.error('编辑器上传图片失败：' + res.message)
        }
      })
    },
    /**
     * YGH  添加loading
     */
    setFormLoading(e) {
      this.formLoading = e
    },
    setDetail(data) {
      this.info = data
      const { content, documentFileList } = data
      this.content = content
      this.fileIds = documentFileList
      this.formLoading = false
    },
    // 清空页面缓存值
    resetDetail() {
      this.formLoading = false
      this.$refs.mytable && this.$refs.mytable.resetFormInfo()
      this.$refs.myform && this.$refs.myform.resetList()
      let info = {
        name: '发文稿纸',
        fileIds: [],
        attach: [],
        draftCompany: [],
        draftId: [
          {
            type: 'userName',
            corpId,
            userId,
          },
        ],
        checkIds: [],
      }
      this.content = ''
      this.fileIds = []
      this.$set(this, 'info', info)
    },
    changeStatus(evt) {
      this.$emit('changeStatus', evt)
    },
    close() {
      this.$emit('close')
      this.content = '输入正文'
      this.$refs.mytable.reset()
    },
    //提交续审数据
    submitRenewalData() {
      const values = this.getFormObj()
      const obj = {
        id: values?.id,
        name: values.name,
        title: values.wgbt,
        urgencyDegree: Number(values.jjcd) || '',
        secrecyDegree: Number(values.miji) || '',
        draftId: this.filter(values.ngry_list),
        // fileType: Number(values.xzfn) || '',
        fileIds: values.fileIds,
        content: this.content,
        documentNumber: values.fwzh,
        draftCompany: this.getCorpName(values.ngdwListInput) || this.filter(values.ngdw_list),
        deviseOpinion: values.nbyj,
        tellPhone: values.lxdh,
      }
      this.handleSubmit(obj)
    },
  },
  destroyed() {
    this.formLoading = false
  },
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.font {
  font-size: 16px;
}
.body {
  max-width: 1280px;
  margin: auto;
}
.left-com {
  // padding: 20px;
  background: #fff;
  // width: 792px;
}
.process {
  padding: 20px 25px;
  padding-top: 115px;
}
.submit-bt {
  text-align: right;
  margin: 40px 0 40px;
  button {
    // width: 100px;
    height: 36px;
    background: #3b9afa;
    border-radius: 4px;
    margin-right: 10px;
  }
}
.info-title {
  width: 1279px;
  height: 66px;
  background: #ffffff;

  font-size: 18px;
  color: #565656;
  margin-bottom: 20px;
  line-height: 66px;
  button {
    margin: 0 10px;
    color: #c1c1c1;
  }
}
.bdr {
  border-radius: 4px;
}
.line {
  margin: 30px 0 15px;
  font-size: 18px;
  font-weight: bold;
  color: #3e3d3d;
}
.line:first-child {
  margin-top: 0;
}
.line::before {
  content: '';
  width: 4px;
  height: 20px;
  background: #3b9afa;
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 10px;
}
</style>