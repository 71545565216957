<template>
  <a-modal
    title="添加"
    :width="1300"
    :visible="visible"
    @ok="handleSubmit"
    @cancel="handleCancel"
    :destroyOnClose="true"
  >
    <a-row :gutter="24">
      <a-col :md="5" :sm="24" class="left-box">
        <a-tabs default-active-key="1" @change="callback" class="tabs-tree">
          <a-tab-pane key="1" tab="组织架构">
            <a-card :bordered="false" :loading="treeLoading">
              <div v-if="this.orgTree != ''">
                <a-tree :treeData="orgTree" v-if="orgTree.length" :replaceFields="replaceFields">
                  <template slot="custom" slot-scope="item">
                    <div class="flex0" @click="handleClick(item)">
                      <!-- <a-icon v-if="item.slots.icon == 'tags'" type="folder-open" theme="twoTone" class="blue-ic" />
                      <a-icon v-if="item.slots.icon == 'tag'" type="folder" theme="twoTone" class="blue-ic" /> -->
                      <img src="/images/directory.svg" alt="" v-if="item.slots.icon == 'tags'" class="blue-ic">
                      <img src="/images/menu.svg" alt="" v-if="item.slots.icon == 'tag'" class="blue-ic">
                      <OpenData type="departmentName" :openid="item.id" :corpid="item.corpId"></OpenData>
                    </div>
                  </template>
                </a-tree>
              </div>
              <div v-else>
                <a-empty :image="simpleImage" />
              </div>
            </a-card>
          </a-tab-pane>
          <a-tab-pane key="2" tab="标签" force-render>
            <template v-if="tags.length > 0">
              <a-card style="border:none;">
                <div class="tagContainer">
                  <div class="tags">
                    <ul>
                      <li
                        class="linContainer"
                        :class="{
                          active: tagActive === tagIndex
                        }"
                        @click="changeActive(tag, tagIndex)"
                        v-for="(tag, tagIndex) in tags"
                        :key="tag.id">
                        <div class="flex0">
                          <div>
                            <img class="tagIcon" src="/images/tag.svg" alt="">
                          </div>
                          <div>
                            {{ tag.name }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </a-card>
            </template>
            <template v-else>
              <a-empty :image="simpleImage" />
            </template>
          </a-tab-pane>
        </a-tabs>
      </a-col>
      <a-col :md="14" :sm="24">
        <x-card>
          <div slot="content" class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :md="8" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.searchValue" allow-clear placeholder="请输入姓名、账号" />
                  </a-form-item>
                </a-col>

                <a-col :md="8" :sm="24">
                  <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                  <a-button style="margin-left: 8px" @click="reset">重置</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </x-card>
        <a-card :bordered="false">
          <s-table
            ref="table"
            :columns="columns"
            :data="loadData"
            :alert="options.alert"
            :rowKey="(record) => record.id"
            :rowSelection="options.rowSelection"
          >
            <span slot="userId" slot-scope="text,record">
              <div>
                <OpenData type="userName" :openid="text" :corpid="record.corpId"></OpenData>
              </div>
            </span>
            <span slot="action" slot-scope="text, record">
              <span>{{ record.userId }}</span>
            </span>
          </s-table>
        </a-card>
      </a-col>

      <a-col :md="5" :sm="24">
        <a-card :bordered="false">
          <div class="yxz">已选择-{{ selectedRows_new.length + selectedRows.length }}</div>
          <div v-for="(o, i) in selectedRows" :key="i" class="flex user-list">
            <OpenData type="userName" :openid="o.userId" :corpid="o.corpId"></OpenData>
          </div>
          <div v-for="(o, i) in selectedRows_new" :key="i + 'new'" class="flex user-list">
            <OpenData type="userName" :openid="o.userId" :corpid="o.corpId"></OpenData>
            <a-button type="link" icon="close-circle" class="close-circle-bt" @click="del_user" />
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'

import { STable, XCard } from '@/components'
import { Empty } from 'ant-design-vue'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { wx_org, corpDeptPage } from '@/api/modular/fileStream/user'
import { documentLablePage, getLablePersonPage } from '@/api/modular/fileStream/documentLabelManage'
export default {
  components: {
    STable,
    XCard,
    OpenData,
  },
  data() {
    return {
      visible: false,
      sp_index: null,
      tags: [],
      tagActive: 0,
      tabActive: '1',
      user_list_name: null,
      userId_arr: [],
      deptIds: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '姓名',
          dataIndex: 'userId',
          width: '150px',
          scopedSlots: { customRender: 'userId' },
        },
        {
          title: '账号',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
        {
          title: '企业名称',
          dataIndex: 'corpName',
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        if (this.tabActive === '2') {
          return getLablePersonPage(Object.assign(parameter, { labelId: this.tagActiveRow.id }, this.queryParam)).then(res => res.data)
        }
        return corpDeptPage(Object.assign(parameter, this.deptIds, this.queryParam)).then((res) => {
          return res.data
        })
      },
      orgTree: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectedRows_new: [],
      sexDictTypeDropDown: [],
      statusDictTypeDropDown: [],
      treeLoading: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      replaceFields: {},
      options: {
        alert: {
          show: false,
          clear: () => {
            this.selectedRowKeys = []
          },
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
          type: 'radio',
        },
      },
    }
  },
  created() {
    this.getTree()
    this.sysDictTypeDropDown()
    this.getTags()
  },
  methods: {
    changeActive(tag, tagIndex) {
        this.tagActive = tagIndex
        this.tagActiveRow = tag
        this.$refs.table.refresh()
    },
    reset() {
      this.queryParam = {}
      this.$refs.table.refresh()
    },
    getTree() {
      /**
       * 获取到机构树，展开顶级下树节点，考虑到后期数据量变大，不建议全部展开
       */
      wx_org(Object.assign(this.queryParam))
        .then((res) => {
          this.treeLoading = false
          if (!res.success) {
            return
          }
          //
          const mydata = Object.assign([], res.data)
          function make_icon(data) {
            data.forEach((element) => {
              element.scopedSlots = { title: 'custom' }
              element.children && element.children.length == 0
                ? (element.slots = { icon: 'tag' })
                : (element.slots = { icon: 'tags' })
              if (element.children && element.children.length > 0) {
                make_icon(element.children)
              } else {
                return
              }
            })
          }
          make_icon(mydata)
          //
          this.orgTree = mydata
        })
        .then((x) => {
          init_wx()
        })
    },
    add(list, i) {
      console.log(list, i)

      this.sp_index = i
      this.selectedRows_new = []
      this.userId_arr = []
      //
      if (i === undefined) {
        this.user_list_name = list
        //this.selectedRows = this.$parent[list]
      } else if (typeof i === 'number') {
        this.user_list_name = list
        //this.selectedRows = this.$parent[list][i].val
      }
      //
      this.selectedRows.map((x) => {
        this.userId_arr.push(x.userId)
      })
      console.log(this.selectedRows, this.userId_arr)
      //
      this.visible = true
    },
    sexFilter(sex) {
      const values = this.sexDictTypeDropDown.filter((item) => item.code == sex)
      if (values.length > 0) {
        return values[0].value
      }
    },
    statusFilter(status) {
      const values = this.statusDictTypeDropDown.filter((item) => item.code == status)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 获取字典数据
     */
    sysDictTypeDropDown(text) {
      sysDictTypeDropDown({ code: 'sex' }).then((res) => {
        this.sexDictTypeDropDown = res.data
      })
      sysDictTypeDropDown({ code: 'common_status' }).then((res) => {
        this.statusDictTypeDropDown = res.data
      })
    },

    /**
     * 点击左侧机构树查询列表
     */
    handleClick(it) {
      this.deptIds = { deptId: it.id, corpId: it.corpId }
      this.$refs.table.refresh(true)
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      if (selectedRows.length > 0 && this.userId_arr.indexOf(selectedRows[0].userId) == -1) {
        this.selectedRows_new = selectedRows
      }
    },
    handleSubmit() {
      const { sp_index, selectedRows, selectedRows_new, user_list_name } = this
      const length = selectedRows.length + selectedRows_new.length
      if (length < 1) {
        this.$message.error('请选择！')
        return
      } else {
        const rows = [].concat(selectedRows).concat(selectedRows_new)
        if (typeof sp_index === 'number') {
          this.$parent[user_list_name][sp_index].val = rows
        } else {
          this.$parent[user_list_name] = rows
        }
        console.log(rows)
        this.visible = false
      }
    },

    handleCancel() {
      this.visible = false
    },
    del_user() {
      this.selectedRows_new = []
    },
    callback(key) {
      console.log(key)
      this.tabActive = key
      this.$refs.table.refresh()
    },
    getTags() {
        documentLablePage().then((res) => {
            if (res.code === 200) {
              this.tags = res.data.rows
              this.tagActiveRow = res.data.rows.length > 0 ? res.data.rows[0] : {}
            } else {
                this.$message.warning(res.message)
            }
        })
    }
  },
}
</script>
<style lang="less" scoped>
@import url('../../../common');
.table-operator {
  margin-bottom: 18px;
}
button {
  margin-right: 8px;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex0 {
  display: flex;
  align-items: center;
}
.blue-ic {
  // font-size: 1.5em;
  width: 21px;
  height: 21px;
  margin-right: 10px;
}
.user-list {
  margin-bottom: 10px;
}

.yxz {
  margin-bottom: 20px;
  font-weight: bold;
}

.left-box {
  padding: 0 !important;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ccc;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }
}
::v-deep .ant-tabs-bar {
  margin: 0;
}
::v-deep .ant-tabs-nav-scroll {
  display: flex;
  justify-content: space-around;
}
</style>
